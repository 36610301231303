import { useNavigate, useParams, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Form, message, Upload, Skeleton, Breadcrumb, Modal, Tag } from "antd";
import { PageHeaderDetailForm } from "../PageHeader";
import { getUserByEmail, deleteUser } from "@services/userService";
import { getUserRoleById } from "@services/systemService";
import { StringHelper } from "@utilities";
import { InboxOutlined } from "@ant-design/icons";
import GenericConfirmModal from "@dashboardmodals/GenericConfirmModal";
import { AppObject } from "@context/AppObject";
import GenericDetailsPage from "../../GenericComponenets/GenericDetailsPage";
import "../GameAsset/css/assetcontainer.css";
import useAuth from "@hooks/useAuth";
import { Role } from "@context/Role";
import { updateLogo } from "@services/userService";

const UserDetails = () => {
  
  const [formData] = Form.useForm();
  const [user, setUser] = useState({});
  const [role, setRole] = useState({});
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const { email } = useParams();
  const navigate = useNavigate();
  const [uploadModel, setuploadModel] = useState(false);
  const [msg, setMessage] = useState("");
  const [ToBeDeletedEmail, setToBeDeletedEmail] = useState();

  const [isUpdating, setUpdating] = useState(false);
  const { auth } = useAuth();
  const [disabled, setIsDisabled] = useState(true);
  const [status, setStatus] = useState("");
  const [statusText, setStatusText] = useState("");
  const [userImageFile, setUserImageFile] = useState();
  const [imageFile, setImageFile] = useState();
  // const [okayButtonDisabled, setOkayButtonDisabled] = useState(true);
  const updateThumbnail = () => {
    setUpdating(true);
    let formlogo = new FormData();
    formlogo.append("thumbnail", userImageFile);
    formlogo.append("lastUpdatedBy", auth?.user?.email);
    updateLogo(user?.email, formlogo)
      .then((response) => {
        if (response.status === 201) {
          message.success("The logo is updated successfully");
          setImageFile(response.data.thumbnail);
          setuploadModel(false);
        } else if (response.status === 401) {
          message.error("Please login and try again");
          return navigate("/login");
        } else if (response.status === 500) {
          message.error("No Response From Server. Please try after sometime");
        }
        setUpdating(false);
      })
      .catch((err) => {
        setUpdating(false);
        message.error("Unable to update user. Please try again later.");
      });
  };
  const getUser = (email) => {
    return new Promise((resolve, reject) => {
      getUserByEmail(email)
        .then((response) => {
          if (response.data?.user) {
            setImageFile(response.data.user.thumbnail);
            resolve(response.data.user);
          } else {
            reject(new Error("User not found"));
          }
        })
        .catch((error) => reject(error));
    });
  };
  const uploadProps = {
    onRemove: (file) => {
      setIsDisabled(true);
      setUserImageFile(undefined);
    },
    beforeUpload: (file) => {
      return false;
    },
    onChange: (info) => {
      setIsDisabled(false);
      const fsize = info.file.size;
      const img = Math.round(fsize / 1024);
      if (img > 1024) {
        setStatus("error");
        setStatusText("File should be less than 1 Mb (" + parseFloat(fsize / 1024 / 1024).toFixed(2) + "Mb)");
        setUserImageFile();
        setIsDisabled(true);
        return false;
      } else {
        setStatus("success");
        setStatusText(`${parseFloat(fsize / 1024 / 1024).toFixed(2)}Mb`);
        setUserImageFile(info.file);
      }
      if (info.file.status !== "uploading") {
        let reader = new FileReader();
        reader.readAsDataURL(info.file);
        setUserImageFile(info.file);
      }
    },
    // userImageFile,
    maxCount: 1,
    listType: "picture",
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const getRole = (roleId) => {
    return new Promise((resolve, reject) => {
      getUserRoleById(roleId)
        .then((response) => {
          if (response.data?.role) {
            resolve(response.data.role);
          } else {
            setLoading(false);
            reject(new Error("Role not found"));
          }
        })
        .catch((error) => reject(error));
    });
  };

  const DeleteUser = () => {
    deleteUser(ToBeDeletedEmail).then((response) => {
      setMessage("User by email " + ToBeDeletedEmail + "Successfully deleted!");
      message.success("User by email " + ToBeDeletedEmail + "Successfully deleted!");
      setVisible(false);
      navigate("/users");
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await getUser(email);
        setUser(userResponse);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [email]);

  useEffect(() => {
    setLoading(true);
    if (user.roles && user.roles.length > 0) {
      getRole(user.roles[0])
        .then((roleResponse) => {
          setRole(roleResponse);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [user]);

  const isOwnerDetails = () => {
    return user?.roles?.includes(Role.OWNER);
  };

  const isCurrentUserOwner = () => {
    return auth?.user?.roles?.includes(Role.OWNER);
  };

  const isCurrentDetailsBelongsToUser = () => {
    return user?.email === auth?.user.email;
  };

  const onDelete = () => {
    // stop user to delete there own account
    if (!isCurrentDetailsBelongsToUser() && !isOwnerDetails()) {
      return () => setVisible(true);
    }
  };

  const onEdit = () => {
    //Only Owner should be able to edit his own account
    if (isOwnerDetails() && !isCurrentUserOwner()) {
      // stop user to edit there own account
      return;
    }
    return () => navigate(`/users/update/${user.email}`, { state: { userData: { ...user }, role: { ...role } } });
  };
  
 
  const onThumbnailUpdate = () => {
    //Only Owner should be able to edit his own account
    if (isOwnerDetails() && !isCurrentUserOwner()) {
      // stop user to edit there own account
      return;
    }
    return () => setuploadModel(true);
  };

  return (
    <Skeleton loading={loading} active>
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/dashboard">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/users">Users</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{user.name}</Breadcrumb.Item>
        </Breadcrumb>

        <div style={{ paddingTop: "15px" }}>
          <GenericConfirmModal
            open={visible}
            onCancel={() => setVisible(!visible)}
            onOk={() => DeleteUser()}
            okText="Delete"
            okButtonProps={{ disabled: ToBeDeletedEmail != user?.email }}
          >
            {msg === "" ? (
              <div>
                <p>Please put the Email to delete the user!</p>
                <input
                  type="text"
                  placeholder={user.email}
                  onChange={(e) => setToBeDeletedEmail(e.target.value)}
                />{" "}
              </div>
            ) : (
              <p>{msg}</p>
            )}
          </GenericConfirmModal>

          <GenericDetailsPage
          header={<PageHeaderDetailForm
            objectId={AppObject.USER}
            title={
              <div style={{ fontSize: "16px", fontWeight: 600, color: "#000000" }}>
                {`User Details - ${user?.email}`}
                <Tag color="#00b96b" style={{ marginLeft: "8px" }}>
                  {StringHelper.toFirstLetterUpperCase(role?.roleName)}
                </Tag>
              </div>
            } 
            onDelete={onDelete()}
            className={{ width: "100% " }}
            onEdit={onEdit()}
            onCancel={() => navigate("/users")}
          />}
            objectId={AppObject.USER}
            thumbnail={imageFile}
            onUpdateImage={onThumbnailUpdate()}
            name={user?.name}
            role={role}
            items={{
              Name: user?.name,
              Email: user?.email,
              Role: StringHelper.toFirstLetterUpperCase(role.roleName),
              Active: user?.active,
              "Created At": new Date(user?.createdAt).toUTCString(),
              "Password": (
                <Link to="/users/updatepassword" state={{ user }} style={{ color: "#00b96b" }}>
                  Update Password
                </Link>
              ),
            }}
          />
          <Modal
            open={uploadModel}
            title="Upload New Image"
            onOk={() => updateThumbnail()}
            onCancel={() => setuploadModel(false)}
            confirmLoading={isUpdating}
            okText="Upload"
            okButtonProps={{ disabled }}
          >
            <Form form={formData}>
              <Form.Item
                name="files"
                hasFeedback={true}
                validateStatus={status}
                help={statusText}
                extra
                valuePropName="thumbnail"
                getValueFromEvent={normFile}
              >
                <Upload.Dragger name="thumbnail" {...uploadProps} accept="image/jpeg, image/png,image/jpg">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">Only jpeg or png files are supported.</p>
                </Upload.Dragger>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </React.Fragment>
    </Skeleton>
  );
};

export default UserDetails;
