import React, { useEffect, useState, useMemo } from "react";
import {
  Descriptions,
  Button,
  Image,
  Card,
  Col,
  Row,
  Skeleton,
  message,
  Breadcrumb,
  Tooltip,
  Space,
  Input,
  Table,
  Form,
  Empty,
  Tabs,
  Typography,
} from "antd";
import "./css/EnvDetailsV2.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { StringHelper } from "@utilities";
import { AppObject } from "@context/AppObject";
import InputModal from "@dashboardmodals/InputModal";
import { generatePrivateAssetAPIKEY, getPrivateAssetAPIKEY, deletePrivateAssetAPIKEY } from "@services/apiKeyService";
import CopyToClipboard from "react-copy-to-clipboard";
import { CopyFilled, CopyOutlined, DeleteOutlined, InfoCircleOutlined, TableOutlined } from "@ant-design/icons";
import { CancelButton } from "../../buttons/CancelButton";
import { EditButton } from "../../buttons/EditButton";
import GenericConfirmModal from "../../modals/GenericConfirmModal";
import SuccessModel from "../../modals/Success";
import FailureModel from "../../modals/Failure";
import useAuth from "@hooks/useAuth";
import { getEnvById, getTotalAssetVersionService, getTotalAssetCollectionCount } from "@services/EnvService";
import CustomDoughnut from "../../custom-charts/CustomDoughnut";
import IButton from "../../buttons/IButton";

const { Title } = Typography;
const { TabPane } = Tabs;

function EnvironmentDetailsV2() {
  const { envId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [env, setEnv] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isAPIKeyLoading, setAPIKeyLoading] = useState(false);
  const [inputModalVisible, setInputModalVisibility] = useState(false);
  const [salt, setSalt] = useState();
  const [apiKey, setAPIKey] = useState();
  const [apiKeys, setApiKeys] = useState([]);
  const [isAPIKeyCopied, setAPIKeyCopied] = useState(false);
  const [DoubleConfirmModal, setDoubleConfirmModal] = useState(false);
  const [ConfirmModal, setConfirmModal] = useState(false);
  const [maskedKey, setMaskedKey] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [successModalVisiblilty, setSuccessModalVisiblilty] = useState(false);
  const [failureModalVisiblilty, setFailureModalVisiblilty] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState();
  const { hasAccess, ACTIONS } = useAuth();
  const [getTotalAssetVersions, setGetTotalAssetVersions] = useState();
  const [totalAssetCollectionCount, setTotalAssetCollectionCount] = useState();

  const getTotalAssetVersion = (envId) => {
    getTotalAssetVersionService(envId)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setGetTotalAssetVersions(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error("Unable to load total asset versions.");
        setLoading(false);
      });
  };

  const getTotalACdeployedCount = (envId) => {
    getTotalAssetCollectionCount(envId)
      .then((res) => {
        if (res.status === 200 && res.data) {
          console.log("RES :", res.data);
          setTotalAssetCollectionCount(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error("Unable to load total deployed Asset Collection");
      });
  };

  const handleCopy = () => {
    setAPIKeyCopied(true);
    setTimeout(() => {
      setAPIKeyCopied(false);
    }, 2000);
  };

  useEffect(() => {
    getApiKeys();
    getTotalACdeployedCount(envId);
    getTotalAssetVersion(envId);
    getEnvById(envId)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setEnv(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error("Unable to load environment.Please try again later");
        setLoading(false);
      });
  }, [envId]);

  const fetchAPIKey = () => {
    setAPIKeyLoading(true);
    generatePrivateAssetAPIKEY({ salt, envId })
      .then((res) => {
        setAPIKeyLoading(false);
        getApiKeys();
        setSalt("");

        setAPIKey(res.data?.apiKey);
        setApiKeys(res?.data?.downloadAPIKeys);
      })
      .catch((error) => {
        setAPIKeyLoading(false);
      });
  };
  const getApiKeys = () => {
    getPrivateAssetAPIKEY(envId)
      .then((res) => {
        setApiKeys(res?.data?.downloadAPIKeys);
      })
      .catch((err) => {
        message.error("Unable to load API keys. Please try again later");
        setLoading(false);
      });
  };

  const toggleDoubleConfirmModal = () => {
    setConfirmModal(false);
    setDoubleConfirmModal(!DoubleConfirmModal);
  };
  const toggleConfirmModal = () => {
    setConfirmModal(!ConfirmModal);
  };

  const goBack = () => {
    navigate(-1);
  };

  const editbtn = () => {
    navigate("/environments/update/" + envId);
  };

  const tableBtn = () => {
    navigate("/environments/table", { state: { env, envId: envId } });
  };

  const assetColTable = () => {
    navigate(`/environments/${envId}/assetColTable`, { state: { env, envId: envId } });
  };

  const setKeyAndTriggerModal = (record) => {
    toggleConfirmModal();
    setSelectedDeleteId(record.maskedKey);
  };
  const deleteKey = () => {
    setConfirmLoading(true);
    if (maskedKey !== selectedDeleteId) {
      setConfirmLoading(false);
      toggleDoubleConfirmModal();
      setFailureModalVisiblilty(true);
    } else {
      deletePrivateAssetAPIKEY(maskedKey, envId)
        .then((res) => {
          setConfirmLoading(false);
          resetStates();
          getApiKeys();
        })
        .catch((err) => {
          setFailureModalVisiblilty(true);
        });
    }
  };
  const resetStates = () => {
    setMaskedKey();
    setSelectedDeleteId();
    toggleDoubleConfirmModal();
    setSuccessModalVisiblilty(true);
  };

  const columns = [
    {
      title: "Masked key",
      dataIndex: "maskedKey",
      key: "maskedKey",
      render: (_, record) => {
        return <span>{"*************" + record.maskedKey}</span>;
      },
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Created  At",
      dataIndex: "createdAt",
      key: "createdAt",
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return apiKeys?.length <= 1 ? null : (
          <Space size="large">
            <DeleteOutlined onClick={() => setKeyAndTriggerModal(record)} />
          </Space>
        );
      },
    },
  ];

  const defaultCount = useMemo(() => {
    return getTotalAssetVersions?.deployedAssets?.find((item) => item.state === "DEFAULT")?.count || 0;
  }, [getTotalAssetVersions]);

  const liveCount = useMemo(() => {
    return getTotalAssetVersions?.deployedAssets?.find((item) => item.state === "LIVE")?.count || 0;
  }, [getTotalAssetVersions]);

  const offlineCount = useMemo(() => {
    return getTotalAssetVersions?.deployedAssets?.find((item) => item.state === "OFFLINE")?.count || 0;
  }, [getTotalAssetVersions]);

  const totalCount = useMemo(() => {
    return defaultCount + liveCount + offlineCount;
  }, [defaultCount, liveCount, offlineCount]);

  const liveCollectionCount = useMemo(() => {
    return totalAssetCollectionCount?.deployedAssets?.find((item) => item.state === "LIVE")?.count || 0;
  }, [totalAssetCollectionCount]);

  const offlineCollectionCount = useMemo(() => {
    return totalAssetCollectionCount?.deployedAssets?.find((item) => item.state === "OFFLINE")?.count || 0;
  }, [totalAssetCollectionCount]);

  return (
    <Skeleton loading={isLoading}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/environments">Environments</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{env?.envName}</Breadcrumb.Item>
      </Breadcrumb>

      <div className="env-div-top" style={{ display: "flex" }}>
        <p>{envId}</p>
        <CancelButton onClick={goBack} />
      </div>
      <div className="env-div">
        <Row
          gutter={[
            { xs: 8, sm: 16, md: 24, lg: 24 }, // horizontal gutter
            { xs: 8, sm: 16, md: 24, lg: 24 }, // vertical gutter
          ]}
          style={{ marginBottom: "24px", display: "flex" }} // Ensuring gap between rows
        >
          <Col xs={24} sm={24} md={10} lg={12} style={{ display: "flex", flexDirection: "column" }}>
            <Card
              extra={<EditButton onClick={editbtn} />}
              title="Environment Details"
              hoverable
              className="details_Card"
              style={{
                border: "none !imprtant",
                minHeight: 380,
              }}
            >
              <Row>
                <Col>
                  <Descriptions labelStyle={{ width: "120px" }}></Descriptions>
                </Col>
              </Row>
              <Row>
                <Col span={16}>
                  <Descriptions
                    style={{
                      width: 350,
                      paddingLeft: "15px",
                    }}
                    labelStyle={{ width: "120px" }}
                    column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3 }}
                  >
                    <Descriptions.Item
                      label="Env ID"
                      span={3}
                      labelStyle={{ width: "90px", justifyContent: "flex-end" }}
                    >
                      {env?.envId}
                    </Descriptions.Item>

                    <Descriptions.Item
                      label="Env Name"
                      span={3}
                      labelStyle={{ width: "90px", justifyContent: "flex-end" }}
                    >
                      {env?.envName}
                    </Descriptions.Item>

                    <Descriptions.Item
                      label="Created At"
                      span={3}
                      labelStyle={{ width: "90px", justifyContent: "flex-end" }}
                    >
                      {new Date(env?.createdAt).toUTCString()}
                    </Descriptions.Item>

                    <Descriptions.Item
                      label="Created By"
                      span={3}
                      labelStyle={{ width: "90px", justifyContent: "flex-end" }}
                    >
                      {env?.createdBy}
                    </Descriptions.Item>

                    <Descriptions.Item
                      label="Local Cache"
                      labelStyle={{ width: "90px", justifyContent: "flex-end" }}
                      span={3}
                    >
                      {env?.localCacheControl ? (
                        <p>
                          Enabled ({env.localCacheMaxAge} Min){" "}
                          <InfoCircleOutlined title="This is browser based local cache" />
                        </p>
                      ) : (
                        "Disabled"
                      )}
                    </Descriptions.Item>

                    {env?.description ? (
                      <Descriptions.Item
                        label="Description"
                        span={3}
                        labelStyle={{ width: "90px", justifyContent: "flex-end" }}
                      >
                        {env?.description}
                      </Descriptions.Item>
                    ) : null}
                    
                  </Descriptions>
                </Col>
                <Col span={8}>
                  {env?.envImageURL ? (
                    <Image style={{ height: "150px", width: "150px" }} src={env?.envImageURL} />
                  ) : (
                    <h1
                      style={{
                        background: "#eee",
                        height: "150px",
                        width: "150px",
                        borderRadius: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "64px",
                        textAlign: "center",
                      }}
                    >
                      {StringHelper.getInitials(env?.envName, true)}
                    </h1>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} sm={24} md={10} lg={12} style={{ display: "flex", flexDirection: "column" }}>
            <Card
              hoverable
              className="details_Card"
              style={{
                border: "none !important",
                minHeight: 380,
              }}
            >
              <Tabs
                defaultActiveKey="1"
                animated={{
                  inkBar: true, //  animation for the ink bar (tab indicator)
                  tabPane: true, // animation for the content pane
                }}
                tabBarStyle={{
                  transition: "all 0.3s ease", // CSS transition for the inkBar
                }}
              >
                <TabPane key="1" tab={<span>Deployed Versions</span>}>
                  {totalCount === 0 && defaultCount === 0 && liveCount === 0 && offlineCount === 0 ? (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 250 }}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Deployed Versions" />
                    </div>
                  ) : (
                    <div style={{ height: 250, transition: "opacity 0.3s ease" }}>
                      <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                        <Button
                          onClick={tableBtn}
                          icon={<TableOutlined />}
                          disabled={totalCount === 0}
                          style={{
                            width: 40,
                            height: 40,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                      </div>
                      <CustomDoughnut default={defaultCount} offline={offlineCount} live={liveCount} />
                    </div>
                  )}
                </TabPane>

                <TabPane tab="Asset Collection" key="2">
                  <div style={{ height: 250, transition: "opacity 0.3s ease" }}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                      <Button
                        onClick={assetColTable}
                        icon={<TableOutlined />}
                        disabled={totalCount === 0}
                        style={{
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      />
                    </div>
                    <CustomDoughnut offline={offlineCollectionCount} live={liveCollectionCount} />
                  </div>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>

        <Row
          gutter={[
            { xs: 8, sm: 16, md: 24, lg: 24 },
            { xs: 8, sm: 16, md: 24, lg: 24 },
          ]}
        >
          <Col xs={24} sm={24} md={12} lg={24}>
            <Card hoverable style={{ height: "auto" }}>
              {hasAccess(AppObject.API_KEY, ACTIONS.POST) && (
                <Descriptions
                  title={`Virtual Asset API Keys for ${env?.envName} Environment`}
                  style={{
                    width: "auto",
                    paddingLeft: "20px",
                  }}
                >
                  <Descriptions.Item span={3}>API Key is required while downloading the asset.</Descriptions.Item>
                  <Descriptions.Item span={3}>
                    You can create maximum three Api Keys for an environment.
                  </Descriptions.Item>
                  <Descriptions.Item span={3}>
                    {apiKey && (
                      <div style={{ display: "flex", justifyContent: "left", flexDirection: "column" }}>
                        <p>
                          {apiKey}
                          <CopyToClipboard text={apiKey} onCopy={handleCopy}>
                            <Tooltip title="Copy API Key">
                              <Button type="text" icon={isAPIKeyCopied ? <CopyFilled /> : <CopyOutlined />} />
                            </Tooltip>
                          </CopyToClipboard>
                        </p>
                        <p style={{ marginTop: 10, color: "GrayText" }}>
                          Please save this API Key. This is the only time it is getting displayed. If you navigate away
                          from this page, the API key will not be displayed again.
                        </p>
                      </div>
                    )}
                  </Descriptions.Item>

                  <Descriptions.Item span={3}>
                    {apiKeys?.length === 3 ? null : (
                      <Button
                        className="env-btn"
                        type="primary"
                        loading={isAPIKeyLoading}
                        onClick={() => setInputModalVisibility(true)}
                      >
                        Generate New API Key
                      </Button>
                    )}
                  </Descriptions.Item>

                  <Descriptions.Item span={3}>
                    <Table
                      columns={columns}
                      dataSource={apiKeys}
                      style={{ width: "100%" }}
                      pagination={{ hideOnSinglePage: true }}
                    />
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Card>
          </Col>
        </Row>
      </div>

      <InputModal
        open={inputModalVisible}
        title="Add Salt"
        okText="Generate API Key"
        okButtonProps={{ disabled: !salt || salt?.length < 8 }}
        onCancel={() => {
          setSalt("");
          setInputModalVisibility(false);
        }}
        onOk={() => {
          setInputModalVisibility(false);
          fetchAPIKey();
        }}
        centered
        nestorOptions={{
          message: "Please enter minimum 8 character to generate API Key.",
          inputItems: [
            {
              placeholder: "Please add characters",
              maxLength: 64,
              value: salt,
              onChange: (e) => {
                setSalt(e?.target?.value);
              },
            },
          ],
        }}
      />

      {/* <Divider /> */}

      <GenericConfirmModal
        title={"Are you sure to delete this API key?"}
        onOk={toggleDoubleConfirmModal}
        okText="Delete"
        danger
        visible={ConfirmModal}
        onClose={toggleConfirmModal}
      >
        <div style={{ marginTop: "10px" }}>
          <Form form={form}>
            <Form.Item required requiredMark>
              <p style={{ marginRight: "0%", textAlign: "left", width: "100%" }}>
                Please enter the last 4 characters of the API Key.
              </p>
              <Input
                max={4}
                maxLength={4}
                min={1}
                minLength={1}
                value={maskedKey}
                placeholder={"Last 4 digit of the API Key"}
                onChange={(e) => setMaskedKey(e.target.value)}
              />
            </Form.Item>
          </Form>
        </div>
      </GenericConfirmModal>

      {/*  */}
      <GenericConfirmModal
        confirmLoading={confirmLoading}
        title={"You are about to delete this API key?"}
        onOk={deleteKey}
        style={{ color: "red" }}
        okText="Confirm"
        visible={DoubleConfirmModal}
        onClose={toggleDoubleConfirmModal}
      >
        You are going to delete the API key, this may stop downloading your assets.
        <p style={{ color: "black" }}>Please confirm to delete.</p>
      </GenericConfirmModal>

      <SuccessModel
        visible={successModalVisiblilty}
        onRightClick={() => setSuccessModalVisiblilty(false)}
        onLeftClick={() => navigate("/environments")}
        leftTitle="Environments List"
        rightTitle="Close"
        title="API key deleted Successfully"
        msg={"You have successfully deleted the API key."}
        onClose={() => setSuccessModalVisiblilty(false)}
      />
      <FailureModel
        visible={failureModalVisiblilty}
        onRightClick={() => {
          setDoubleConfirmModal(false);
          setFailureModalVisiblilty(false);
        }}
        onLeftClick={() => {
          setFailureModalVisiblilty(false);
          setDoubleConfirmModal(false);
          setConfirmModal(true);
        }}
        onClose={() => setFailureModalVisiblilty(false)}
        title={"Invalid API Key"}
        msg="You have entered wrong API Key"
      />
    </Skeleton>
  );
}

export default EnvironmentDetailsV2;
