import { useNavigate, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Col, Row, Skeleton, Breadcrumb,Pagination } from "antd";
import { EmptyView } from "@vyomr/nestor_components";
import { getAllEnv } from "../../../../services/EnvService";
import EnvList from "../../cards/EnvList";
import { AppObject } from "@context/AppObject";
import { PageHeaderListForm } from "@dashboardpages/PageHeader";
import useAuth from "@hooks/useAuth";
const Environment = () => {
  const navigate = useNavigate();
  const { hasAccess, ACTIONS } = useAuth();
  const [envData, setEnvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [showTotal, setShowTotal] = useState();

  const pageSize = 50;

  const fetchEnvironments = () => {
    setLoading(true);
    const query = `pageNumber=${currentPage}&pageSize=${pageSize}`;
    getAllEnv(query)
    .then((data) => {
      console.log("ENVDATA",data.data);
      setEnvData(data?.data?.environments);
      setTotalItems(data?.data?.environments.length)
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchEnvironments();
  }, [currentPage,pageSize]);

  const TotalComponent = () => {
    return (
      <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "end", marginBlock: "10px" }}>
        {showTotal}
        <Pagination
          {...paginationConfg}
          style={{
            textAlign: "left",
          }}
        />
      </div>
    );
  };
  const paginationConfg = {
    total: parseInt(totalItems),
    responsive: true,
    pageSize: pageSize,
    current: currentPage,
    onChange: setCurrentPage,
    showSizeChanger: false,
    showTotal: (total, range) => setShowTotal(`${range[0]}-${range[1]} of ${total} Collections`),
  };
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };



  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Environments</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeaderListForm
        objectId={AppObject.ENVIRONMENT}
        title="Environments"
        onAdd={() => {
          navigate("add");
        }}
        // onSearch={onSearch}
        // searchPlaceHolderText="Search Environments"
      />
      <Row gutter={[32, 32]} style={{ padding: 10 }}>
        <Skeleton loading={loading}>
        {totalItems > 0 && <TotalComponent />}
          {envData.length > 0 ? (
            envData.map((item, index) => {
              return (
                <EnvList
                  key={index}
                  envName={item.envName}
                  style={{ margin: 10, height: "250px" }}
                  description={item.description}
                  envId={item?.envId}
                  image={item?.envImageURL}
                />
              );
            })
          ) : (
            <Col span={24}>
              <EmptyView
                onButtonClick={() => navigate("add", { replace: true })}
                disabled={!hasAccess(AppObject.ENVIRONMENT, ACTIONS.POST)}
                msg="No Environment Data available. Please create new one."
                buttonText="Add Environment"
              />
            </Col>
          )}
        </Skeleton>
        {totalItems > pageSize && (
        <div
          style={{
            marginTop: 15,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Pagination current={currentPage} total={totalItems} pageSize={pageSize} onChange={onPageChange} showSizeChanger={false}/>
        </div>
      )}
      </Row>
    </React.Fragment>
  );
};

export default Environment;
