import React, { useEffect, useState } from "react";
import { Spin, Breadcrumb, Image, Button, Radio, Tooltip, Row, Col, Card, message, Modal, Skeleton } from "antd";
import Title from "antd/lib/typography/Title";
import { PageHeader } from "@ant-design/pro-layout";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DeleteButton } from "../../buttons/DeleteButton";
import { CancelButton } from "../../buttons/CancelButton";
import { getAssetItemById, deleteAssetItem } from "@services/assetCollection";
import AssetVersionPreviewComponent from "@components/preview/AssetVersionPreviewComponent";
import { getAssetTypes } from "@services/systemService";
import { CloudDownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import useAuth from "@hooks/useAuth";
import { AppObject } from "@context/AppObject";
import "./css/AssetCollectionItemDetail.css";
const envconfig = require("@localconfig/envconfig");

function AssetCollectionItemDetail() {
  const { collectionId, assetItemId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [assetItem, setAssetItem] = useState(null);
  const [assetTypeMap, setAssetTypeMap] = useState({});
  const [assetType, setAssetType] = useState(null);
  const [isAssetFileLoading, setAssetFileLoading] = useState(false);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState({});
  const { hasAccess, ACTIONS } = useAuth();
  const location = useLocation();
  const { editable } = location.state || {};

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchAssetTypes = async () => {
      try {
        const response = await getAssetTypes();
        if (response.status === 200) {
          const assetTypes = response.data;
          const assetTypeMap = assetTypes.reduce((acc, asset) => {
            acc[asset.typeId] = asset.typeName;
            return acc;
          }, {});
          setAssetTypeMap(assetTypeMap);
        } else {
          message.error("Unable to load asset types");
        }
      } catch (error) {
        message.error("Unable to load asset types");
      }
    };

    const fetchAssetItemDetails = async () => {
      try {
        const response = await getAssetItemById(collectionId, assetItemId);
        if (response.status === 200) {
          const assetItemData = response.data;
          setAssetItem(assetItemData);
          const assetTypeResponse = await getAssetTypes();
          const assetType = assetTypeResponse.data.find((type) => type.typeId === assetItemData.assetType);
          setAssetType(assetType);
        } else {
          message.error("Failed to fetch asset item details");
        }
      } catch (error) {
        message.error("An error occurred while fetching asset item details");
      } finally {
        setLoading(false);
      }
    };

    fetchAssetTypes();
    fetchAssetItemDetails();
  }, [collectionId, assetItemId]);

  const handleDelete = async (collectionId, assetItemId) => {
    setDeleteLoading((prev) => ({ ...prev, [assetItemId]: true }));
    try {
      const response = await deleteAssetItem(collectionId, assetItemId);
      if (response.status === 200) {
        message.success("Asset Item deleted successfully !");
        navigate(`/assetcollection/${collectionId}`);
      } else {
        message.error("Failed to delete Asset Item");
      }
    } catch (error) {
      message.error("An error occurred while deleting the item");
    } finally {
      setDeleteLoading((prev) => ({ ...prev, [assetItemId]: false }));
      setDeleteModalVisible(false);
    }
  };
  const showDeleteModal = () => {
    setDeleteModalVisible(true);
  };

  const handleCancel = () => {
    setDeleteModalVisible(false);
  };

  const convertBytesToMB = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2); // Convert bytes to MB and round to 2 decimal places
  };

  const getAssetTypeDisplayName = (typeId) => {
    const typeName = assetTypeMap[typeId];
    return typeName ? `${typeName} (${typeId})` : typeId;
  };

  const getPreviewUrl = () => {
    if (assetItem?.assetItemId) {
      return `${envconfig.GREEN_BACKEND_API_URL}/asset-items-files/${encodeURIComponent(assetItem?.assetItemId)}`;
    }
  };

  const getAcItemDetailBtns = () => {
    const getAcItemDetailDeleteBtn = () => {
      const extras = [];
      if (hasAccess(AppObject.ASSET_COLLECTION_EDITABLE, ACTIONS.POST)) {
        extras.push(
          // <DeleteButton key="deleteButton" type="primary" onClick={showDeleteModal} />
          <Button size="large" shape="circle" onClick={showDeleteModal} disabled={editable === false}>
            <DeleteOutlined />
          </Button>
        );
      }
      return extras;
    };

    return (
      <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        <Tooltip title="Download">
          <Button shape="circle" size="large">
            <Spin spinning={isAssetFileLoading}>
              <a href={getPreviewUrl()} style={{ padding: 0, fontWeight: 500, color: "black" }}>
                <CloudDownloadOutlined key="download" />
              </a>
            </Spin>
          </Button>
        </Tooltip>
        {getAcItemDetailDeleteBtn()}
        <CancelButton key="cancelButton" onClick={goBack} />
      </div>
    );
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/assetcollection"}>Asset Collections</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/assetcollection/${collectionId}`}>{collectionId}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{assetItemId}</Breadcrumb.Item>
      </Breadcrumb>

      <div style={{ paddingTop: "15px" }}>
        <Row
          gutter={[
            { xs: 8, sm: 16, md: 24, lg: 24 },
            { xs: 8, sm: 16, md: 24, lg: 24 },
          ]}
        >
          <Col lg={{ span: 24 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Card hoverable={false} className="" bordered={false}>
              <PageHeader
                className="site-page-header"
                title={
                  <div style={{ fontSize: "16px", fontWeight: 600, color: "#000000" }}>
                    {`Item Details - ${assetItem?.assetItemName}`}
                  </div>
                }                
                extra={[getAcItemDetailBtns()]}
              />
              <Row style={{ paddingTop: "40px" }}>
                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ height: "auto" }}>
                  <div>
                    <AssetVersionPreviewComponent file={getPreviewUrl()} type={assetType?.typeName} />
                  </div>
                </Col>

                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <div id="card-info">
                    <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                      <span className="versionDetails-col" style={{ marginRight: 5 }}>
                        <p className="label">Item ID :</p>
                      </span>
                      {assetItem?.assetItemId}
                    </Title>

                    <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                      <span className="versionDetails-col" style={{ marginRight: 5 }}>
                        <p className="label">Item Name :</p>
                      </span>
                      {assetItem?.assetItemName}
                    </Title>

                    <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                      <span className="versionDetails-col" style={{ marginRight: 5 }}>
                        <p className="label"> Size :</p>
                      </span>
                      {convertBytesToMB(assetItem?.size)} MB
                    </Title>

                    <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                      <span className="versionDetails-col" style={{ marginRight: 5 }}>
                        <p className="label">Asset Type :</p>
                      </span>
                      {getAssetTypeDisplayName(assetItem?.assetType)}
                    </Title>

                    <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                      <span className="versionDetails-col" style={{ marginRight: 5 }}>
                        <p className="label">Mime Type :</p>
                      </span>
                      {assetItem?.mimetype}
                    </Title>

                    <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                      <span className="versionDetails-col" style={{ marginRight: 5 }}>
                        <p className="label">Parent Collection :</p>
                      </span>
                      {assetItem?.parentCollections}
                    </Title>

                    <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                      <span className="versionDetails-col" style={{ marginRight: 5 }}>
                        <p className="label">Created By :</p>
                      </span>
                      {assetItem?.createdBy}
                    </Title>

                    <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                      <span className="versionDetails-col" style={{ marginRight: 5 }}>
                        <p className="label">Created At :</p>
                      </span>
                      {assetItem?.createdAt}
                    </Title>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        title="Are you sure you want to delete this item?"
        visible={deleteModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="delete"
            type="primary"
            loading={deleteLoading[assetItemId]}
            onClick={() => handleDelete(collectionId, assetItemId)}
          >
            Delete
          </Button>,
        ]}
      >
        <p>This action cannot be undone.</p>
      </Modal>
    </>
  );
}

export default AssetCollectionItemDetail;
