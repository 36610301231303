import React, { useEffect, useState } from "react";
import { Form, Upload, message } from "antd";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "../css/form.css";
import GenericFormContainer from "../GenericFormContainer";
import SubmitButton from "@dashboard/buttons/SubmitButton";
import Email from "@dashboard/formFields/Email";
import SelectField from "@dashboard/formFields/SelectField";
import FullName from "@dashboard/formFields/FullName";
import ActiveSwitch from "@dashboard/formFields/ActiveSwitch";
import { getUserRoles } from "@services/systemService";
import { StringHelper } from "@utilities";
import { InboxOutlined } from "@ant-design/icons";
import { updateUser } from "@services/userService";
import useAuth from "@hooks/useAuth";
import SuccessModel from "@dashboardmodals/Success";

const UpdateUser = () => {
  const location = useLocation();
  const [formData] = Form.useForm();
  const [userData, setUserData] = useState(location?.state?.userData);
  const [role, setRole] = useState(location?.state?.role);
  const [isFormValid, setIsFormValid] = useState(true);
  const [isUpdating, setUpdating] = useState(false);
  const [userRoles, setUserRole] = useState([]);
  const [userImageFile, setUserImageFile] = useState();
  const [successModalVisiblilty, setSuccessModalVisiblilty] = useState(false);
  const [isChanged, setIsChanged] = useState(false); //
  const navigate = useNavigate();
  const authData = useAuth();

  const labelColSpan = 5;
  const wrapperColSpan = 14;

  useEffect(() => {
    getUserRoles().then((response) => {
      const roleOptions = response.data?.roles?.map(({ roleName, roleId }) => {
        return { label: StringHelper.toFirstLetterUpperCase(roleName), value: roleId };
      });
      setUserRole(roleOptions);
    });
  }, []);

  const uploadProps = {
    onRemove: (file) => {
      setUserImageFile(undefined);
    },
    beforeUpload: (file) => {
      setUserImageFile(file);
      return false;
    },
    userImageFile,
    maxCount: 1,
    listType: "picture",
  };

  const onSubmit = () => {
    updateUserRole();
  };

  const updateUserRole = () => {
    setUpdating(true);
    formData.setFieldsValue({ userImage: userImageFile });
    const formDataReq = new FormData();
    formDataReq.append("name", formData.getFieldValue("name"));
    formDataReq.append("active", formData.getFieldValue("active"));
    formDataReq.append("roles[0]", formData.getFieldValue("role") || role?.roleId);
    if (userImageFile) {
      formDataReq.append("thumbnail", userImageFile, userImageFile?.name);
    }
    formDataReq.append("lastUpdatedBy", authData?.auth?.user?.email);

    updateUser(userData?.email, formDataReq)
      .then((response) => {
        setSuccessModalVisiblilty(true);
        setUpdating(false);
      })
      .catch((err) => {
        setUpdating(false);
        message.error("Unable to update user. Please try again later.");
      });
  };

  const navigateToUserList = () => {
    setSuccessModalVisiblilty(false);
    navigate(-1);
  };

  //
  const onFormValuesChange = (changedValues, allValues) => {
    const hasChanges = Object.keys(changedValues).length > 0;
    setIsChanged(hasChanges);
  };

  const checkFormValidity = (data, all) => {
    return !!!formData.getFieldsError().filter(({ errors }) => errors.length).length;
  };

  const resetFunc = () => {
    formData.resetFields();
    const isValid = checkFormValidity();
    setIsFormValid(isValid);
  };

  const onFieldsChange = () => {
    const isValid = checkFormValidity();
    setIsFormValid(isValid);
  };

  const isCurrentDetailsBelongsToUser = () => {
    return userData?.email === authData?.auth?.user?.email;
  };

  return (
    <React.Fragment>
      <GenericFormContainer
        formData={formData}
        path="users"
        formName={
          <div style={{ fontSize: "16px", fontWeight: 600, color: "#000000" }}>
            {`Update User - ${userData?.email}`}
          </div>
        }
        breadcrumbPath={"usersUpdatePath"}
        resetFunc={resetFunc}
        navigate={() => navigate(`/users/${userData?.email}`)}
      >
        <Form
          form={formData}
          labelCol={{ span: labelColSpan }}
          onFieldsChange={onFieldsChange}
          initialValues={{
            email: userData.email,
            name: userData.name,
            active: userData.active,
          }}
          wrapperCol={{ span: wrapperColSpan }}
          className={"form"}
          onValuesChange={onFormValuesChange} //
        >
          <FullName  rules={[{ required: true, message: "Full name is required" }]}/>
          <Email disabled={true} />
          <SelectField
            labelColSpan={labelColSpan}
            wrapperColSpan={wrapperColSpan}
            disabled={isCurrentDetailsBelongsToUser()}
            options={userRoles}
            value={role?.roleId}
          />
          {isCurrentDetailsBelongsToUser() || <ActiveSwitch />}

          <Form.Item label="User Image">
            <Form.Item name="userImage" valuePropName="userImageFile" noStyle>
              <Upload.Dragger name="files" {...uploadProps} accept="image/jpeg, image/png,image/jpg">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Only jpeg or png files are supported.</p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <SubmitButton title="Update User" onFinish={onSubmit} isDisabled={!isChanged || isUpdating} loading={isUpdating} />
        </Form>
      </GenericFormContainer>
      <SuccessModel
        visible={successModalVisiblilty}
        onRightClick={navigateToUserList}
        onClose={() => {
          setSuccessModalVisiblilty(false);
        }}
        rightTitle="Done"
        title="Successful"
        msg="User has been updated successfully"
      />
    </React.Fragment>
  );
};

export default UpdateUser;
