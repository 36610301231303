import { Badge, Card, Col, Spin, Image } from "antd";
import { useNavigate } from "react-router-dom";
import { StringHelper } from "@utilities";
const UserCard = (props) => {
  const navigate = useNavigate();
  const ribbon = props.ribbon;

  return (
    <Col span={6} key={props.key}>
      {ribbon ? (
        <Badge.Ribbon text={ribbon.charAt(0).toUpperCase() + ribbon.slice(1)} color="#00b96b" style={{ zIndex: 1 }} />
      ) : (
        <Spin className="spin-kit" style={{ position: "absolute", top: 15, right: 30, zIndex: 2 }} />
      )}

      <Card
        hoverable
        className="user-data"
        cover={
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {props.thumbnail ? (
              <img
                alt={props.initials}
                src={props.thumbnail}
                style={{ width: props.thumbnail ? "100%" : 200, height: 150, objectFit: "scale-down" }}
              />
            ) : (
              <h1
                style={{
                  background: "#eee",
                  height: "150px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "74px",
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                {StringHelper.getInitials(props.initials, true)}
              </h1>
            )}
          </div>
        }
        onClick={() => navigate(`${props.link}`)}
      >
        <Card.Meta title={props.title} />
      </Card>
    </Col>
  );
};

export default UserCard;
