import React, { useState } from "react";
import { Form, message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import "../css/form.css";
import GenericFormContainer from "../GenericFormContainer";
import SubmitButton from "@dashboard/buttons/SubmitButton";
import Password from "@dashboard/formFields/Password";
import ConfirmPassword from "@dashboard/formFields/ConfirmPassword";
import useAuth from "@hooks/useAuth";
import { updateUser } from "@services/userService";
import SuccessModel from "@dashboardmodals/Success";

const UpdateUserPassword = () => {
  const location = useLocation();
  const [formData] = Form.useForm();
  const [userData, setUserData] = useState(location?.state?.user);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isUpdating, setUpdating] = useState(false);
  const [successModalVisiblilty, setSuccessModalVisiblilty] = useState(false);

  const navigate = useNavigate();
  const authData = useAuth();

  const navigateToUserList = () => {
    setSuccessModalVisiblilty(false);
    navigate(-1);
  };

  const onSubmit = () => {
    updateUserRole();
  };

  const checkFormValidity = () => {
    return (
      formData.isFieldTouched("password") &&
      formData.isFieldTouched("confirmPass") &&
      !!!formData.getFieldsError().filter(({ errors }) => errors.length).length
    );
  };

  const resetFunc = () => {
    formData.resetFields();
    setIsFormValid(checkFormValidity());
  };

  const onFieldsChange = () => {
    const isValid = checkFormValidity();
    setIsFormValid(isValid);
  };

  const updateUserRole = () => {
    setUpdating(true);
    const formDataReq = new FormData();
    formDataReq.append("password", formData.getFieldValue("password"));
    formDataReq.append("confirmPassword", formData.getFieldValue("confirmPass"));
    formDataReq.append("lastUpdatedBy", authData?.auth?.user?.email);

    updateUser(userData?.email, formDataReq)
      .then((response) => {
        setSuccessModalVisiblilty(true);
        setUpdating(false);
      })
      .catch((err) => {
        setUpdating(false);
        message.error("Unable to update user's password. Please try again later.");
      });
  };
  return (
    <React.Fragment>
      <GenericFormContainer
        formData={formData}
        path="users"
        formName={"Update Password - " + userData?.userId && "Update Password"}
        breadcrumbPath={"usersUpdatePasswordPath"}
        resetFunc={resetFunc}
        navigate={() => navigateToUserList()}
      >
        <Form
          form={formData}
          labelCol={{ span: 5 }}
          onFieldsChange={onFieldsChange}
          wrapperCol={{ span: 14 }}
          className={"form"}
        >
          <Password />
          <ConfirmPassword />
          <SubmitButton title="Update Password" onFinish={onSubmit} isDisabled={!isFormValid} loading={isUpdating} />
        </Form>
      </GenericFormContainer>
      <SuccessModel
        visible={successModalVisiblilty}
        onClose={() => {
          setSuccessModalVisiblilty(false);
        }}
        onRightClick={navigateToUserList}
        rightTitle="Done"
        title="Successful"
        msg="User password has been updated successfully"
      />
    </React.Fragment>
  );
};

export default UpdateUserPassword;
