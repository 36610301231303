import { DeleteOutlined } from "@ant-design/icons";
import { Button , Spin , Tooltip} from "antd";

export const DeleteButton = (props) => {
  return ( 
    <Tooltip title={props.title}>
    {props?.loading?<Spin  style={{
      width: 40,
      height: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color:'#00b96b'
    }}/>:
    <Button
      key={props?.key || 1}
      style={{
        width: 40,
        height: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={props?.onClick}
    >
      <DeleteOutlined />
    </Button>}
    </Tooltip>
  );
};
